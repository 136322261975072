<router-outlet></router-outlet>
<div
  class="flex flex-col h-screen bg-white"
  style="background: url('/assets/images/bg-illustration.png') no-repeat; background-position: left bottom; background-size: 330px;"
>
  <!-- Header Section -->
  <div class="flex justify-between items-center p-8 bg-white">
    <!-- Logo -->
    <div class="logo__container flex justify-start">
      <a href="#" class="cursor-pointer">
        <img
          class="w-auto mango-logo"
          src="assets/images/svgs/mamvest-logo.svg"
          alt="Mamvest Logo"
        />
      </a>
    </div>

    <!-- Profile Dropdown -->
    <div class="flex justify-end gap-x-4 lg:gap-x-6">
      <div class="relative">
        <button
          type="button"
          class="-m-1.5 flex items-center p-1.5 focus-visible:outline-mango"
          id="user-menu-button"
          aria-expanded="false"
          aria-haspopup="true"
          (click)="toggleProfileModal()"
        >
          <div class="flex flex-col items-end text-right">
            <span class="font-semibold text-gray-700">Vivian Adams</span>
            <div class="flex items-center space-x-2">
              <span class="text-[#58595B] text-sm">Individual Account</span>
              <img src="/assets/images/svgs/tier--one.svg" alt="Tier 1" class="h-5 w-auto" />
            </div>
          </div>
          <img
            class="h-8 w-8 rounded-full bg-gray-50 ml-3"
            src="/assets/images/avatar-image.png"
            alt="Profile Picture"
          />
          <span class="hidden lg:flex lg:items-center">
            <svg
              class="ml-2 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  </div>

  <!-- Main Content Section -->
  <div class="flex-1 overflow-y-auto p-8">
    <!-- Dynamic Content -->
    <ng-content></ng-content>

  </div>
</div>
